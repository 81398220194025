import Loading from "ook-loading";

import "./ab-form-button.scss";

const FormButton = ({ loading, children, onClick, disabled, ...rest }) => {
  return (
    <button
      className="ab-form-button"
      type="submit"
      onClick={onClick}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loading loading mini /> : children}
    </button>
  );
};

export default FormButton;
