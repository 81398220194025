import React, { useEffect, useRef } from "react";
import { useInViewport } from "react-in-viewport";
import Text from "ab-text";
import Gallery from "ab-gallery";
import Link from "ab-link";
import ParagraphText from "ab-paragraph-text";
import FormDedicatoria from "ab-form-dedicatoria";
import "./carrousel-half.scss";

const CarrouselHalf = ({
  title,
  link,
  text = [],
  content,
  rtl,
  plus,
  className = "",
  classname = "",
  images = ["/images/Food.png", "/images/Rooms.png"],
  imgmob = [],
  animated,
  h1,
  video,
  videoembed,
  navlink,
  products,
  product,
  i18n,
  showUpsellingModal,
  setShowUpsellingModal,
}) => {
  // console.log('CarrouselHalf', images);

  const myRef = useRef();

  const { inViewport, enterCount, leaveCount } = useInViewport(
    myRef,
    { threshold: 0.5 },
    {
      onEnterViewport: () => {
        // console.log("entered viewport");
      },
    },
    { disconnectOnLeave: false }
  );
  const handleScroll = () => {
    const element = document.getElementById(`menu-#${navlink}`);
    const menu = document.getElementById("history-menu");

    if (element) {
      if (inViewport) {
        if (!element?.classList?.contains("selected")) {
          const children = menu?.querySelectorAll("li");
          children.forEach(function(e) {
            if (e.id !== element.id) {
              e?.classList.remove("selected");
              e?.classList.add("not-selected");
            }
          });
          element?.classList.add("selected");
          element?.classList.remove("not-selected");
          menu.scrollLeft = element?.offsetLeft - 10;
        }
      } else {
        if (!element?.classList?.contains("not-selected")) {
          element?.classList.remove("selected");
          element?.classList.add("not-selected");
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [inViewport]);

  return (
    <>
      {navlink ? (
        <a className="reference" style={{ top: -90 }} id={navlink} />
      ) : null}

      <div
        className={`carrousel-half${rtl ? " rtl" : ""}${
          plus ? " plus" : ""
        } ${className || ""} ${classname || ""}`}
        ref={myRef}
      >
        {h1 ? <Text element="h1">{h1}</Text> : null}
        <div className="row">
          <div className={`content${navlink ? " ebox" : ""}`}>
            <Text element="h2" animated={animated}>
              {title}
            </Text>

            <Gallery images={images} imagesMobile={imgmob} />
            <ParagraphText text={text} content={content} singleColumn />

            {classname?.includes("experiencia") ? (
              <FormDedicatoria
                i18n={i18n}
                products={products}
                product={product}
                showUpsellingModal={showUpsellingModal}
                setShowUpsellingModal={setShowUpsellingModal}
              />
            ) : null}

            {link?.href || link?.url ? (
              <Link
                prefetch={false}
                href={link?.href || link?.url}
                text={link?.text || link?.title}
                target={link?.target}
              />
            ) : null}
          </div>
          {video ? (
            video
          ) : videoembed ? (
            <iframe
              width="1216"
              height="600"
              src={`${videoembed.replace(
                "https://www.youtube.com/watch?v=",
                "https://www.youtube-nocookie.com/embed/"
              )}?modestbranding=1&controls=0&showinfo=0&mode=opaque&autohide=1&wmode=transparent`}
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          ) : (
            <Gallery images={images} imagesMobile={imgmob} />
          )}

          {link?.href || link?.url ? (
            <Link
              prefetch={false}
              href={link?.href || link?.url}
              text={link?.text || link?.title || "Enlace"}
              target={link?.target}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CarrouselHalf;
